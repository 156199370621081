import cx from "classnames";
import { type FC } from "react";

import { Avatar } from "./Avatar";
import { type AvatarProps } from "./types";

export const AvatarWithLabel: FC<AvatarProps> = (props) => {
	return (
		<div className="flex min-w-0 items-center gap-2">
			<Avatar {...props} />
			{props.label && (
				<div
					className={cx(
						"truncate",
						props.size === "xxs" && "text-xxs",
						props.size === "xs" && "text-xs",
						props.size === "sm" && "text-sm",
						props.size === "md" && "text-md",
						props.size === "lg" && "text-lg",
						props.size === "xl" && "text-xl"
					)}
				>
					{props.label}
				</div>
			)}
		</div>
	);
};
