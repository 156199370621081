import cx from "classnames";
import type { FC, ReactNode } from "react";

type AppTopBarListProps = {
	grow?: boolean;
	children?: ReactNode;
};

export const AppTopBarList: FC<AppTopBarListProps> = ({ children, grow }) => (
	<ul className={cx("flex items-center min-w-0 gap-2", grow && "flex-1")}>{children}</ul>
);

export type { AppTopBarListProps };
