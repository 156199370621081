import { type TooltipLevel } from "./types";

export const getTooltipClassNames = (level: TooltipLevel) => [
	"border",
	"border-inverse-primary",
	"rounded",
	"data-[side=bottom]:animate-slideDownAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideUpAndFade",
	{
		"bg-inverse-secondary text-inverse-primary": level === "primary",
		"bg-primary shadow-surround-sm": level === "secondary",
	},
	"p-2",
	"text-sm",
	"z-tooltip",
];
