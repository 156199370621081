import cx from "classnames";
import type { FC } from "react";

const TEXT_BG_COLORS = [
	"bg-dark-spectrum-red",
	"bg-dark-spectrum-yellow",
	"bg-dark-spectrum-green",
	"bg-dark-spectrum-cyan",
	"bg-dark-spectrum-blue",
	"bg-dark-spectrum-purple",
	"bg-dark-spectrum-pink",
];

// Returns a deterministic color based on a hash of the provided string from TEXT_BG_COLORS
// There will be duplicates between character ranges (e.g. a -> d) which can be minimized
// by adding more colors to TEXT_BG_COLORS
export const getColor = (str: string, colors: string[]) => {
	const strArray = str.toLowerCase().split("");
	const charCodeSum = strArray.reduce((arr, char) => arr + char.charCodeAt(0), 0);
	const min = "a".charCodeAt(0) * str.length;
	const max = "z".charCodeAt(0) * str.length;
	const hash = (charCodeSum - min) / (max - min);
	const index = Math.round((colors.length - 1) * hash);

	return colors[index];
};

const getMemoizedGetColor = () => {
	const cache = {};
	return (str: string, colors: string[]) => {
		const key = `${str}_${colors.join("_")}`;
		if (cache[key]) {
			return cache[key];
		}

		const color = getColor(str, colors);
		cache[key] = color;

		return color;
	};
};
const memoizedGetColor = getMemoizedGetColor();

export interface TextAvatarProps {
	userName?: string;
	label?: string;
	className?: string;
	isOverflow?: boolean;
}
export const TextAvatar: FC<TextAvatarProps> = ({ userName, label, className, isOverflow }) => {
	const defaultName = "default"; // Necessary. Otherwise the color is undefined. Can be any value.
	const textBgColorStyle = memoizedGetColor(userName || defaultName, TEXT_BG_COLORS);

	const text = label || userName || "?";

	return (
		<div
			title={text}
			className={cx(
				"flex",
				"items-center",
				"justify-center",
				textBgColorStyle && `${textBgColorStyle} text-inverse-primary`,
				!textBgColorStyle && "bg-tertiary text-primary",
				className
			)}
		>
			<span
				className={cx(
					"font-semibold uppercase no-underline",
					isOverflow && "text-xxs",
					!isOverflow && "text-xs"
				)}
			>
				{isOverflow ? text : text.slice(0, 1)}
			</span>
		</div>
	);
};
