import { ChevronDown } from "@carbon/icons-react";
import cx from "classnames";
import type { ForwardRefRenderFunction, MouseEventHandler, ReactNode } from "react";
import { forwardRef } from "react";

import type { ButtonProps, ButtonVariant } from "../Button";
import { Button } from "../Button";

export type AppTopBarButtonProps = {
	variant?: ButtonVariant;
	level?: ButtonProps["level"];
	className?: string;
	style?: "action" | "dropdown";
	disabled?: boolean;
	maximizeHitArea?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	children: ReactNode;
};

const BaseAppTopBarButton: ForwardRefRenderFunction<HTMLButtonElement, AppTopBarButtonProps> = (
	{
		children,
		variant = "normal",
		level = "tertiary",
		className,
		style,
		disabled,
		maximizeHitArea = true,
		onClick,
		// IMPORTANT: Props spread allows radix-ui to be able to use this in `Trigger`.
		...props
	},
	ref
) => {
	return (
		<div className="flex h-full min-w-0 items-center">
			<Button
				ref={ref}
				className={cx(
					{ "hit-maximizer hit-maximizer--bar": maximizeHitArea },
					"relative",
					"flex",
					"flex-row",
					level === "primary" ? "!px-3" : "!px-2",
					className,
					// remove unneeded button styles
					"!bg-transparent",
					"!border-none",
					"!shadow-none",
					"!outline-none",
					"!ring-0"
				)}
				variant={variant}
				level={level}
				onClick={onClick}
				disabled={disabled}
				{...props}
			>
				{children}
				{style && <ChevronDown size={16} className="scale-50" />}
			</Button>
		</div>
	);
};

export const AppTopBarButton = forwardRef(BaseAppTopBarButton);
