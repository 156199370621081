import { Copy } from "@carbon/icons-react";
import type { FC, HtmlHTMLAttributes, MouseEvent } from "react";

import { Button } from "@moment/design-system/Button";
import { makeToast } from "@moment/design-system/Toast";
import { BasicTooltip } from "@moment/design-system/Tooltip";

import { Logger } from "~/utils/logging";

const logger = new Logger("components/common/CopyText");
export interface CopyTextProps {
	content: string;
	size?: "xs" | "sm";
	hideText?: boolean;
	disabled?: boolean;
	tooltip?: string;
	message?: string;
}

const copyHTMLForFirefox = (content: HTMLElement) => {
	const prosemirrorBody = document.querySelector(".ProseMirror");
	if (!prosemirrorBody) {
		logger.error("Prosemirror body not found.");

		return;
	}

	// Step 1: clone the content node (just in case it's a reference to a node in the DOM)
	const contentCopy = content.cloneNode(true) as HTMLElement;

	// Step 2: Append the contentCopy to the prosemirror body
	// this is necessary to be able to select the content
	prosemirrorBody.appendChild(contentCopy);

	// Step 3: Select the contentCopy element
	const range = document.createRange();
	range.selectNode(contentCopy);
	const selection = window.getSelection() || new globalThis.window.Selection();
	selection.removeAllRanges();
	selection.addRange(range);

	// Step 4: Execute the copy command
	try {
		// Firefox doesn't allow navigator.clipboard.write() out of the box
		// so we need to fall back to the deprecated execCommand API.
		document.execCommand("copy");
	} catch (err) {
		logger.error("Error copying element to clipboard:", { err });
	} finally {
		// Step 5: Clean up by deselecting the contentCopy element
		selection.removeAllRanges();

		// Step 6: remove the contentCopy ndoe from the prosemirror body
		prosemirrorBody.removeChild(contentCopy);
	}
};

export const copyContent = async (content: string | HTMLElement, message?: string) => {
	const onCopySuccess = () => {
		makeToast({
			message: message ?? "Copied to clipboard",
			variant: "success",
		});
	};
	const onCopyError = () => {
		makeToast({
			message: "Failed to copy to clipboard",
			variant: "error",
		});
	};

	if (typeof content === "string") {
		try {
			await navigator.clipboard.writeText(content);
			onCopySuccess();
		} catch (e) {
			onCopyError();
		}
	} else if (
		navigator.userAgent.toLowerCase().includes("firefox") ||
		typeof window.ClipboardItem === "undefined"
	) {
		// More recent versions of Firefox do implement the asynchronous clipboard API, but
		// the implementation is aggressively sanitizing the content on write, removing
		// the critical but non-standard HTML tags - such as <compute_cell>.
		// See: https://github.com/w3c/clipboard-apis/issues/150
		// and: https://github.com/w3c/clipboard-apis/pull/197
		copyHTMLForFirefox(content);
	} else {
		const clipboardItem = new ClipboardItem({
			"text/plain": new Blob([content.textContent ?? ""], {
				type: "text/plain",
			}),
			"text/html": new Blob([content.outerHTML], {
				type: "text/html",
			}),
		});

		try {
			await navigator.clipboard.write([clipboardItem]);
			onCopySuccess();
		} catch (e) {
			onCopyError();
		}
	}
};

export const CopyText: FC<CopyTextProps & HtmlHTMLAttributes<HTMLDivElement>> = ({
	content,
	size = "sm",
	hideText = false,
	disabled,
	tooltip,
	message,
	...props
}) => {
	const onCopyClick = async (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		await copyContent(content, message);
	};
	const copyButton = (
		<Button
			level="tertiary"
			onClick={onCopyClick}
			size={size}
			disabled={disabled}
			icon={Copy}
			label="Copy"
		/>
	);

	return (
		<div className="flex items-center space-x-2">
			{!hideText && <div {...props}>{content}</div>}
			{tooltip ? (
				<BasicTooltip content={tooltip}>{copyButton}</BasicTooltip>
			) : (
				<>{copyButton}</>
			)}
		</div>
	);
};
