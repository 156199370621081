import BoringAvatar from "boring-avatars";
import cx from "classnames";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { match } from "ts-pattern";

import { TextAvatar } from "./TextAvatar";
import { type AvatarProps, AvatarSize, AvatarType } from "./types";

export const AvatarSizeUnits = {
	[AvatarSize.xxs]: "1rem",
	[AvatarSize.xs]: "1.25rem",
	[AvatarSize.sm]: "1.5rem",
	[AvatarSize.md]: "2rem",
	[AvatarSize.lg]: "2.5rem",
	[AvatarSize.xl]: "3rem",
};

const BORING_AVATAR_VARIANT = "marble";
/* TODO: use from tailwind colors */
const BORING_AVATAR_COLORS = ["#3730A3", "#4F46E5", "#A78BFA", "#5EEAD4", "#7DD3FC"];

export const Avatar: FC<AvatarProps> = ({
	userName,
	imageURL,
	avatarType: defaultAvatarType = AvatarType.Image,
	label,
	size = AvatarSize.md,
	style,
	className,
	isOverflow,
}) => {
	const [avatarType, setAvatarType] = useState<AvatarType>(defaultAvatarType);

	const onImageError = () => {
		setAvatarType(AvatarType.Text);
	};

	const onImageLoad = () => {
		setAvatarType(defaultAvatarType);
	};

	const avatarStyles = cx(
		// These !importants are intended to prevent wrapping classes like .italic from applying to the avatar
		"!not-italic",
		"!no-underline",
		"!border-b-0",
		"shrink-0",
		"rounded-full",
		"object-cover",
		"color-[rgba(255,255,255,0)]",
		{
			"text-[0.5rem] w-4 h-4": size === AvatarSize.xxs,
			"text-[0.75rem] w-5 h-5": size === AvatarSize.xs,
			"text-[1rem] w-6 h-6": size === AvatarSize.sm,
			"text-[1.5rem] w-8 h-8": size === AvatarSize.md,
			"text-[2rem] w-10 h-10": size === AvatarSize.lg,
			"text-[2.5rem] w-12 h-12": size === AvatarSize.xl,
		}
	);

	useEffect(() => {
		// Reset avatar type when image changes to handle success/error
		setAvatarType(defaultAvatarType);
	}, [imageURL]);

	const boringAvatarSizes = {
		[AvatarSize.xxs]: "1rem",
		[AvatarSize.xs]: "1.25rem",
		[AvatarSize.sm]: "1.5rem",
		[AvatarSize.md]: "2rem",
		[AvatarSize.lg]: "2.5rem",
		[AvatarSize.xl]: "3rem",
	};

	return (
		<span className="shrink-0" style={style}>
			{match({ type: avatarType, imageURL })
				// Image Types with fallbacks
				.with({ type: AvatarType.Image, imageURL: undefined }, () => (
					<TextAvatar userName={userName} label={label} className={avatarStyles} />
				))
				.with({ type: AvatarType.Image, imageURL: "" }, () => (
					<TextAvatar userName={userName} label={label} className={avatarStyles} />
				))
				.with({ type: AvatarType.Image }, () => (
					<img
						src={imageURL}
						onError={onImageError}
						onLoad={onImageLoad}
						title={userName}
						className={cx(
							"rounded-full",
							"object-cover",
							"bg-quaternary",
							avatarStyles,
							className
						)}
						alt={userName}
					/>
				))

				// Boring
				.with({ type: AvatarType.Boring }, () => (
					<BoringAvatar
						size={boringAvatarSizes[size]}
						name={userName}
						variant={BORING_AVATAR_VARIANT}
						colors={BORING_AVATAR_COLORS}
					/>
				))

				// Text
				.with({ type: AvatarType.Text }, () => (
					<TextAvatar
						userName={userName}
						label={label}
						className={avatarStyles}
						isOverflow={isOverflow}
					/>
				))
				.exhaustive()}
		</span>
	);
};
export { AvatarType };
