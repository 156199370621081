import cx from "classnames";
import type { FC, ReactNode } from "react";

import { useTheme } from "../ThemeProvider";

export type AppShellProps = { children?: ReactNode };

export const AppShell: FC<AppShellProps> = ({ children }) => {
	const { resolvedTheme } = useTheme();
	return (
		<div
			className={cx(
				"flex",
				"h-screen",
				"w-full",
				"flex-col",
				resolvedTheme === "dark" && "bg-primary",
				resolvedTheme === "light" && "bg-secondary"
			)}
		>
			<div className="flex h-full flex-col overflow-hidden">{children}</div>
		</div>
	);
};
