import { Login } from "@carbon/icons-react";
import { useRouter } from "next/router";

import { AppTopBarButton } from "@moment/design-system/AppTopBarButton";
import { AppTopBarItem } from "@moment/design-system/AppTopBarItem";

import { useAuth } from "~/auth/useAuth";

/**
 * <LoginButton/>
 * Login Button for top bar
 */
export const LoginButton = () => {
	const router = useRouter();
	const { loginWithRedirect } = useAuth();
	return (
		<AppTopBarItem>
			<AppTopBarButton onClick={() => loginWithRedirect(router.asPath)}>
				<Login size={20} />
				Login
			</AppTopBarButton>
		</AppTopBarItem>
	);
};
