import cx from "classnames";
import type { ForwardRefRenderFunction, ReactNode } from "react";
import { forwardRef } from "react";

export type AppTopBarItemProps = {
	children: ReactNode;
	grow?: boolean;
};

const BaseAppTopBarItem: ForwardRefRenderFunction<any, AppTopBarItemProps> = (
	{ children, grow },
	ref
) => {
	return (
		<li
			className={cx(
				"relative",
				"h-full",
				"flex",
				grow && "flex-1",
				!grow && "flex-shrink-0 flex-grow-0",
				"items-center",
				"list-none",
				"min-w-0"
			)}
			ref={ref}
		>
			{children}
		</li>
	);
};

export const AppTopBarItem = forwardRef(BaseAppTopBarItem);
