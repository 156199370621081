import * as RadixTooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import type { FC, ForwardRefRenderFunction, RefAttributes } from "react";
import { forwardRef } from "react";

import { ForceTheme } from "../ThemeProvider";
import { type TooltipLevel } from "./types";
import { getTooltipClassNames } from "./utils";

const TooltipProvider: FC<RadixTooltip.TooltipProviderProps> = ({
	children,
	delayDuration = 300,
	...props
}) => {
	return (
		<RadixTooltip.Provider delayDuration={delayDuration} {...props}>
			{children}
		</RadixTooltip.Provider>
	);
};

const TooltipTrigger: ForwardRefRenderFunction<
	HTMLButtonElement,
	RadixTooltip.TooltipTriggerProps & RefAttributes<HTMLButtonElement>
> = ({ children, ...props }, ref) => {
	return (
		<RadixTooltip.Trigger ref={ref} {...props}>
			{children}
		</RadixTooltip.Trigger>
	);
};

export type TooltipContentProps = {
	level?: TooltipLevel;
} & RadixTooltip.TooltipContentProps &
	RefAttributes<HTMLDivElement>;

const TooltipContent: ForwardRefRenderFunction<HTMLDivElement, TooltipContentProps> = (
	{ children, sideOffset = 4, ...props },
	ref
) => {
	let level: TooltipLevel = "primary";
	if (props.level === "secondary") {
		level = props.level;
	}
	return (
		<ForceTheme theme="light">
			<RadixTooltip.Content
				{...props}
				ref={ref}
				sideOffset={sideOffset}
				className={cx(getTooltipClassNames(level), props.className)}
			>
				{children}
			</RadixTooltip.Content>
		</ForceTheme>
	);
};

export const Tooltip = {
	Provider: TooltipProvider,
	Root: RadixTooltip.Root,
	Trigger: forwardRef(TooltipTrigger),
	Portal: RadixTooltip.Portal,
	Content: forwardRef(TooltipContent),
};
