import * as RadixTooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import type { FC, ReactNode } from "react";

import { Tooltip, type TooltipContentProps } from "./Tooltip";

export interface BasicTooltipProps {
	children: ReactNode;
	content: ReactNode;
	triggerProps?: RadixTooltip.TooltipTriggerProps;
	contentProps?: TooltipContentProps;
	withPortal?: boolean;
	withArrow?: boolean;
}
export const BasicTooltip: FC<BasicTooltipProps> = ({
	children,
	content,
	triggerProps = {},
	contentProps = {},
	withPortal = true,
	withArrow = false,
}) => {
	const portalContent = content && (
		<Tooltip.Content side="bottom" {...contentProps}>
			{withArrow && (
				<RadixTooltip.Arrow
					className={cx(
						"z-10",
						contentProps.level === "secondary"
							? "fill-bg-primary"
							: "fill-bg-inverse-primary"
					)}
					width={15}
					height={8}
				/>
			)}
			{content}
		</Tooltip.Content>
	);
	return (
		<Tooltip.Provider>
			<Tooltip.Root>
				<Tooltip.Trigger asChild {...triggerProps}>
					{children}
				</Tooltip.Trigger>
				{withPortal ? <Tooltip.Portal>{portalContent}</Tooltip.Portal> : portalContent}
			</Tooltip.Root>
		</Tooltip.Provider>
	);
};
