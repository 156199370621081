import cx from "classnames";
import type { FC, ReactNode } from "react";

export type AppTopBarProps = {
	children?: ReactNode;
};

export const AppTopBar: FC<AppTopBarProps> = (props) => {
	return (
		<div
			className={cx(
				"sticky",
				"top-0",
				"left-0",
				"right-0",
				"box-content",
				"flex",
				"h-10",
				"shrink-0",
				"grow-0",
				"pr-3",
				"pb-px",
				"shadow-border-inset-bottom",
				"z-surface",
				"@container/topbar",
				"bg-primary"
			)}
		>
			{props.children}
		</div>
	);
};
