import dynamic from "next/dynamic";

import { AppShell } from "@moment/design-system/AppShell";

import { isDesktopAppMode } from "~/auth/interop/common";

import { TopBarDisplay } from "./topbar/TopBar";

const Lazy = {
	StatusBar: dynamic(() => import("~/components/StatusBar").then((m) => m.StatusBar)),
};

export const withAppShell = (Component) => {
	const isDesktop = isDesktopAppMode();
	return function WithAppShell(props) {
		return (
			<AppShell>
				<div className="flex w-full flex-1 overflow-hidden">
					<div className="flex h-full w-full flex-col">
						{isDesktop && <TopBarDisplay />}
						<Component {...props} />
					</div>
				</div>

				{isDesktop && <Lazy.StatusBar />}
			</AppShell>
		);
	};
};
