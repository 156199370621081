import { type FC, type ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import NoSSR from "react-no-ssr";

import { AppTopBar } from "@moment/design-system/AppTopBar";
import { AppTopBarList } from "@moment/design-system/AppTopBarList";

import { useAuth } from "~/auth/useAuth";

import { LoginButton } from "./LoginButton";
import { Profile } from "./Profile";
import { useTopBarContext } from "./TopBarContext";

/**
 * <TopBarDisplay/>
 * This component renders our AppTopBar and mounts a sharable portal
 */

export const TopBarDisplay = () => {
	const { setTopBarPortal } = useTopBarContext();
	const portal = useRef<HTMLDivElement | null>(null);

	const { isAuthenticated } = useAuth();

	useEffect(() => {
		setTopBarPortal(portal);
	}, [setTopBarPortal]);

	return (
		<AppTopBar>
			{/* Where additional top bar elements will be placed */}
			<div ref={portal} className="flex min-w-0 grow gap-4"></div>

			{/* We always want the <Profile/> component visible in the top bar*/}
			<AppTopBarList>
				<NoSSR>{isAuthenticated ? <Profile /> : <LoginButton />}</NoSSR>
			</AppTopBarList>
		</AppTopBar>
	);
};

/**
 * <TopBar/>
 * This component places {children} into <TopBarDisplay/>
 * Use this in route components to change what's included in top bar based off of the current page
 */
export const TopBar: FC<{ children: ReactNode }> = ({ children }) => {
	const { portal } = useTopBarContext();

	if (!portal?.current) {
		return null;
	}

	return ReactDOM.createPortal(<>{children}</>, portal.current);
};
