import { type CSSProperties } from "react";

export enum AvatarSize {
	xxs = "xxs",
	xs = "xs",
	sm = "sm",
	md = "md",
	lg = "lg",
	xl = "xl",
}

export enum AvatarType {
	Boring = "boring",
	Image = "image",
	Text = "text",
}

export type AvatarProps = {
	userName?: string;
	imageURL?: string;
	avatarType?: AvatarType;
	label?: string;
	size?: AvatarSize;
	style?: CSSProperties;
	className?: string;
	isOverflow?: boolean;
};
